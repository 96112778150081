import React, { FC } from 'react';
import { useId } from 'react-id-generator';
import CarouselWrapper from 'gatsby-theme-husky/src/common/CustomCarousel';
import { ShoppingOptionsProps } from 'gatsby-theme-husky/src/components/ShoppingOptions/models';
import ShoppingOptionsCard from 'gatsby-theme-husky/src/components/ShoppingOptions/ShoppingOptionsCard';
import { Col, Row } from 'gatsby-theme-husky/src/layout';
import parseAnchor from 'gatsby-theme-husky/src/utils/parseAnchor';
import classnames from 'classnames';

import 'gatsby-theme-husky/src/components/ShoppingOptions/ShoppingOptions.scss';

const ShoppingOptions: FC<ShoppingOptionsProps> = ({
  shoppingOptionsTitle,
  shoppingOptionsCard,
  secondaryNavigationAnchor,
  backgroundColor,
  sectionTheme,
  showDotsArrowsMobile,
}) => (
  <Row
    className={classnames('shopping-options', {
      [`shopping-options--${sectionTheme}`]: sectionTheme,
      [`shopping-options--${backgroundColor}`]: backgroundColor,
    })}
    data-testid="shopping-options"
    id={parseAnchor(secondaryNavigationAnchor)}
  >
    <Col className="shopping-options__container">
      <h2 className="shopping-options__title">{shoppingOptionsTitle}</h2>
      {shoppingOptionsCard.length ? (
        <CarouselWrapper showDotsArrowsMobile={showDotsArrowsMobile}>
          {shoppingOptionsCard.map((card) => {
            const [dropId] = useId();

            return <ShoppingOptionsCard {...card} key={dropId} />;
          })}
        </CarouselWrapper>
      ) : null}
      <hr className="horizontal-line horizontal-line--with-margin-left-right" />
    </Col>
  </Row>
);

export default ShoppingOptions;
